import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import * as tf from '@tensorflow/tfjs';
// import * as posenet from '@tensorflow-models/posenet';
import * as tmPose from '@teachablemachine/pose'; 

import * as brickBreaker from "./brickBreaker";

import './App.css';
import './Posenet.scss';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon, RedditIcon } from "react-share";

import img1 from './assets/posenet2.png';
import img2 from './assets/brick1.PNG';
import img3 from './assets/left.png';
import img4 from './assets/neutral.png';

let modelPath = `my-pose-model/`; // deploy : 'my-pose-model/'
const shareUrl = `ai.robolink.com`;

let startedGame = false;
let stopLoop = false;
let stopDefaultLoop = false;
let stopCustomLoop = true;
let webcam;
let brickSpeed = 20;
let defaultModel;
let loadedPosenet;
let labelProbabilityContainer;
let areButtonsDisabled = false;
let metaData;
let load = [];
let trainAndValidationImages = [];
let dots = '.';
let dotInterval;

const quizs = [
  {id: 1, question: 'You want to make a model that classifies pose. What data do you need to train the model?', answers: ['Full body images with dotted 17 joints', 'Many different partial body images with dotted joints', 'Estimated pose data from image'], correct: 2},
  {id: 2, question: 'What cannot be used to prevent the model from overfitting?', answers: ['Early stopping', 'Decrease learning rate', 'Decrease hidden layer', 'Decrease number of neurons per layer', 'dropout'], correct: 1}
];

let savedAnswer = new Array(quizs.length).fill(-1);

const Posenet = () => {
  const [ page, setPage ] = useState(0);
  const [ submittedEmail, setSubmittedEmail ] = useState(false);
  const [ loadFormTimes, setLoadFormTimes ] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ label, setLabel ] = useState("right");
  const [ rightProb, setRightProb ] = useState(0);
  const [ leftProb, setLeftProb ] = useState(0);
  const [ neutralProb, setNeutralProb ] = useState(0);
  const [ showProbability, setShowProbability ] = useState(true); 
  const [ speed, setSpeed ] = useState(20);
  const [ usingCustomModel, setUsingCustomModel ] = useState(false);

  const [ numLeftData, setNumLeftData ] = useState(0);
  const [ numNeutralData, setNumNeutralData ] = useState(0);
  const [ numRightData, setNumRightData ] = useState(0);
  const [ showLabel, setShowLabel ] = useState(false);
  
  const [ teachablePage, setTeachablePage ] = useState(0);

  const [ currentDataLabel, setCurrentDataLabel ] = useState('left');
  const [ countLeft, setCountLeft ] = useState(0);
  const [ countRight, setCountRight ] = useState(0);

  const [ training, setTraining ] = useState(true);
  const [ displayDots, setDisplayDots ] = useState('...');
  
  const [ customModelLink, setCustomModelLink ] = useState('');
  const [ currentQuizId, setCurrentQuizId ] = useState(0);
  const [ currentAnswer, setCurrentAnswer ] = useState('');
  const [ score, setScore ] = useState(0);
  // const [ savedAnswer, setSavedAnswer ] = useState(new Array(quizs.length).fill(-1));

  let model, ctx, labelContainer, maxPredictions;

  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    let checkSubmittedEmailInStorage = localStorage.getItem("submittedEmail");
    if (checkSubmittedEmailInStorage) {
      setSubmittedEmail(true);
    }
    else {

    }
  }, []);

  const handleSubmitEmail = () => {
    setLoadFormTimes(loadFormTimes + 1);
    if (loadFormTimes > 0) {
      setTimeout( () => {
        setSubmittedEmail(true);
        localStorage.setItem('submittedEmail', true);
      }, 2000);
    }
  }


  const startDemo = () => {
    setPage(3);
    loadModelAndWebcam(400, 400);
  }

  const loadModelAndWebcam = async(width, height) => {
    setLoading(true);
    const modelUrl = modelPath + "model.json";
    const metadataUrl = modelPath + "metadata.json";
    model = await tmPose.load(modelUrl, metadataUrl);

    maxPredictions = model.getTotalClasses();

    const flip = true;
    webcam = new tmPose.Webcam(width, height, flip);
    // webcam.getWebcam().then(() => {
    //   alert('works!');
    // }, (r) => {
    //   alert(r);
    // });
    // return;
    await webcam.setup();
    await webcam.play();
    window.requestAnimationFrame(loop);

    const canvas = document.getElementById("canvas");
    canvas.width = width; canvas.height = height;
    ctx = canvas.getContext("2d");
    
    // labelContainer = document.getElementById("label-container");
    // for (let i = 0; i < maxPredictions; i++) { // and class labels
    //   labelContainer.appendChild(document.createElement("div"));
    // }

    setLoading(false);
  }

  const loop = async(timestamp) => {
    if(stopLoop) return;
    webcam.update();
    await predict();
    window.requestAnimationFrame(loop);
  }

  const predict = async() => {
    const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);
    const prediction = await model.predict(posenetOutput);

    let leftProbability = prediction[0].probability;
    let neutralProbability = prediction[1].probability;
    let rightProbability = prediction[2].probability;
    
    let probabilities = [leftProbability, neutralProbability, rightProbability];
    let i = probabilities.indexOf(Math.max(...probabilities));
    let label;
    if (i === 0) label = "left";
    else if (i === 1) label = "neutral";
    else label = "right";
    setLabel(label);

    // for (let i = 0; i < maxPredictions; i++) {
    //   const classPrediction =
    //     prediction[i].className + ": " + prediction[i].probability.toFixed(2);
    //   labelContainer.childNodes[i].innerHTML = classPrediction;
    // }/
    setRightProb((rightProbability * 100).toFixed(0));
    setLeftProb((leftProbability * 100).toFixed(0));
    setNeutralProb((neutralProbability * 100).toFixed(0));

    drawPose(pose);
    if(startedGame) {
      brickBreaker.updatePosePos(label, probabilities[i], brickSpeed);
    }
}

  const drawPose = (pose) => {
    if (webcam.canvas) {
      ctx.drawImage(webcam.canvas, 0, 0);
      // draw the keypoints and skeleton
      if (pose) {
        const minPartConfidence = 0.5;
        tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
        tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
      }
    }
  }

  const goToGamePage = async() => {
    try {
      stopLoop = true;
      stopDefaultLoop = true;
      stopCustomLoop = true;
      await webcam.stop();
      setPage(4);
      if (usingCustomModel) {
        clearInterval(brickBreaker.updateInterval);
      }
      setTimeout(async() => {
        startedGame = true;
        let canvas = document.getElementById('gameCanvas');
        brickBreaker.onLoadBrickBreaker(canvas);
        if (usingCustomModel) {
          stopCustomLoop = false;
          await loadWebcam(200, 200);
          await loadCanvas(200, 200);
          window.requestAnimationFrame(runCustomModel);
        } else {
          stopLoop = false;
          stopDefaultLoop = false;
          await loadModelAndWebcam(200, 200);
        }
      }, 500);
    } catch (e) {
      return;
    }
  }

  const goToTrainingPage = async() => {
    try {
      startedGame = false;
      stopLoop = true;
      await webcam.stop();
    } catch (e) {
      startedGame = true;
      stopLoop = false;
      return;
    }
    setPage(5);
  }

  const loadWebcamForTrainingPage = async () => {
    setPage(9);
    await initializeDefaultModel();
    await initializeCustomModelForTraining();
    await loadWebcam(400, 400);
    await loadCanvas(400, 400);
    runDefaultModel();
  }

  const initializeDefaultModel = async () => {
    const modelUrl = modelPath + "model.json";
    const metadataUrl = modelPath + "metadata.json";
    defaultModel = await tmPose.load(modelUrl, metadataUrl);
  }

  const initializeCustomModelForTraining = async() => {
    const metadataUrl = modelPath + "metadata.json";
    let response = await fetch(metadataUrl);
    metaData = await response.json();
    loadedPosenet = await tmPose.createTeachable(metaData);
    loadedPosenet.prepareDataset();
    console.log(loadedPosenet);
  }

  const loadWebcam = async(width, height) => {
    const flip = true;
    webcam = new tmPose.Webcam(width, height, flip);
    await webcam.setup();
    await webcam.play();
    webcam.update();
  }

  const loadCanvas = async(width, height) => {
    const canvas = document.getElementById("canvas");
    canvas.width = width; 
    canvas.height = height;
    ctx = canvas.getContext("2d");
  }

  const runDefaultModel = async() => {
    if(stopDefaultLoop) return;
    webcam.update();
    await predictByDefault();
    window.requestAnimationFrame(runDefaultModel);
  }

  const predictByDefault = async() => {
    const { pose, posenetOutput } = await defaultModel.estimatePose(webcam.canvas);
    drawPose(pose);
    if(startedGame) {
      brickBreaker.updatePosePos(label, brickSpeed);
    }
  }

  const handleCollectDataBtn = async(e) => {
    e.preventDefault();
    areButtonsDisabled = !areButtonsDisabled;

    setTimeout(() => {
      areButtonsDisabled = !areButtonsDisabled;
    }, 2000);

    while (areButtonsDisabled) {
      webcam.update();
      // 0: left, 1: neutral, 2: right
      let i;
      if (currentDataLabel === 'left') i = 0;
      else if (currentDataLabel === 'neutral') i = 1;
      else if (currentDataLabel === 'right') i = 2;
      else return;
      await takePhoto(i);
      setShowLabel(false);
      setShowProbability(false);
    }
  }

  const takePhoto = async(i) => {
    const { pose, posenetOutput } = await loadedPosenet.estimatePose(webcam.canvas);
    await loadedPosenet.addExample(i, posenetOutput); 
    if (i === 0) {
      setNumLeftData(prevNumLeftData => prevNumLeftData + 1) ;
    } else if (i === 1) {
      setNumNeutralData(prevNumNeutralData => prevNumNeutralData + 1);
    } else if (i === 2) {
      setNumRightData(prevNumRightData => prevNumRightData + 1);
    }
  }

  const tryoutCustomModel = () => {
    stopLoop = false;
    setPage(6);
  }

  const onChangeCustomLink = (e) => {
    setCustomModelLink(e.target.value);
  }

  const train = async() => {
    startedGame = false;
    stopDefaultLoop = true;
    await webcam.stop();

    dotInterval = setInterval(() => {
      if(dots === '.') {
        dots = '..';
      } else if (dots === '..') {
        dots = '...';
      } else if (dots === '...') {
        dots = '.';
      } else {
        dots = '...';
      }
      setDisplayDots(dots);
    }, 1000);

    setTraining(true);
    setPage(10);

    const params = {
      denseUnits: 100,
      learningRate: 0.0001,
      batchSize: 16,
      epochs: 50,
    }
    await loadedPosenet.train(params);
    setTraining(false);
    clearInterval(dotInterval);
    setTimeout(() => {
      startDemoWithCustomModel();
    }, 2000);
    console.log(loadedPosenet);
  }

  const startDemoWithCustomModel = async() => {
    setLoading(true);
    setPage(3);
    setUsingCustomModel(true);
    await loadWebcam(400, 400);
    await loadCanvas(400, 400);
    setLoading(false);
    setShowLabel(true);
    setShowProbability(true);
    if (stopCustomLoop) {
      stopCustomLoop = false;
      runCustomModel();
    }
  }

  const runCustomModel = async() => {
    if(stopCustomLoop) return;
    webcam.update();
    await predictByCustom();
    window.requestAnimationFrame(runCustomModel);
  }

  const predictByCustom = async() => {
    const { pose, posenetOutput } = await loadedPosenet.estimatePose(webcam.canvas);
    const prediction = await loadedPosenet.predict(posenetOutput);

    let leftProbability = prediction[0].probability;
    let neutralProbability = prediction[1].probability;
    let rightProbability = prediction[2].probability;

    let probabilities = [leftProbability, neutralProbability, rightProbability];
    let i = probabilities.indexOf(Math.max(...probabilities));
    let label;
    if (i === 0) label = "left";
    else if (i === 1) label = "neutral";
    else label = "right";
    setLabel(label);

    // for (let i = 0; i < maxPredictions; i++) {
    //   const classPrediction =
    //     prediction[i].className + ": " + prediction[i].probability.toFixed(2);
    //   labelProbabilityContainer.childNodes[i].innerHTML = classPrediction;
    // }
    setRightProb((rightProbability * 100).toFixed(0));
    setLeftProb((leftProbability * 100).toFixed(0));
    setNeutralProb((neutralProbability * 100).toFixed(0));

    drawPose(pose);
    if(startedGame) {
      brickBreaker.updatePosePos(label, probabilities[i], brickSpeed);
    }
  }

  const goToQuiz = async() => {
    try {
      stopCustomLoop = true;
      startedGame = false;
      await webcam.stop();
    } catch(e) {
      console.log(e);
      return;
    }
    setPage(12);
    // setSavedAnswer(new Array(quizs.length).fill(-1));
  }

  const selectAnswer = (index, answer) => {
    console.log(index);
    savedAnswer[currentQuizId] = index;
    setCurrentAnswer(answer);
    console.log(savedAnswer);
    // let newSavedAnswer = savedAnswer;
    // newSavedAnswer[currentQuizId] = index;
    // setSavedAnswer(newSavedAnswer);
    // console.log(savedAnswer);
  }

  const submitQuiz = () => {
    setPage(13);
    setCurrentQuizId(0);

    let newScore = 0;
    for(let i=0; i<quizs.length; i++) {
      if(savedAnswer[i] === quizs[i].correct) newScore += 1;
    }
    setScore(newScore);
  }

  const answerClassName = (index) => {
    if (index === quizs[currentQuizId.correct]) {
      return 'answer correct';
    } else {
      if(index === savedAnswer[currentQuizId]) {
        return 'answer wrong';
      } else {
        return 'answer';
      }
    }
  }

  const webcamDiv = (
    <div className='webcam'>
      <video id='webcam' autoPlay playsInline muted
        style={{display: page === 3 ? "": "none"}} />
      <canvas id='canvas' className='poseCanvas'></canvas>
      <div style={{display: showProbability ? '' : 'none'}} id='label-container'></div>
    </div>
  );

  const shareDiv = (
    <div className="share-icons">
      <FacebookShareButton url={shareUrl} className="icon">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="icon">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} className="icon">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={shareUrl} className="icon">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <RedditShareButton url={shareUrl} className="icon">
        <RedditIcon size={32} round />
      </RedditShareButton>
      <EmailShareButton url={shareUrl} className="icon">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );

  return (
    <div className="Posenet">
      { page === 0 && (
        <div className={submittedEmail ? "content page0": "content page0 small"}>
          <header><h1>Robolink AI web app free trial</h1></header>
          <div>
            { submittedEmail ? (
              <div>
                <p>Are you ready to try out skeletal tracking demo?</p>
                <button onClick={() => setPage(11)}>Yes!</button>
              </div>
            ) : (
              <div>
                <p>Hello! Welcome to the Robolink AI web app free trial.</p>
                <p>We are at the very first step, but we are going to show you more AI demo later!</p>
                <p>Before we start, please submit your email address to access free trial AI demo</p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmhBK856H4tkSW-z-3ILxcBHbf47ck96ZyifK4e6X61JfUww/viewform?embedded=true" onLoad={handleSubmitEmail} width="640" height="423" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              </div>
            )}
          </div>
        </div>
      )}

      { page === 11 && (
        <div className='content page11'>
          <h2>Skeletal tracking demo</h2>

          <button onClick={() => setPage(1)}>Learn</button>
          <button onClick={() => setPage(5)}>Train</button>
        </div>
      )}

      { page === 1 && (
        <div className="content page1">
          <h2>Intro</h2>
          <div className="description">
            <iframe src="https://drive.google.com/file/d/1DzAAzYEYJvpibqt-tNEv11qBuiZzVQ44/preview" width="640" height="400"></iframe>
          </div>
          <button onClick={()=> setPage(2)}>Next</button>
        </div>
      )}

      { page === 2 && (
        <div className="content curriculum">
          <h2>What is skeletal tracking<br/>and how does it work?</h2>
          <div className="description row">
            <div className='col-6'>
              <img className='teachable-machine-example' src={img1} alt="example" />
            </div>
            <div className='col-6'>
              As mentioned before, a skeletal tracking AI analyzes a person’s pose in an image or video by identifying key points on a person’s face and body.
              These key points are the nose, eyes, and ears as well as joints such as the shoulders, elbows, wrists, hips, knees, and ankles.
              Once the AI is able to identify those key points, it can track your movements to determine what kind of pose or gestures you are making.
            </div>
          </div>
          <div className='description'>In the example image, you can clearly see how the AI is tracking the nose, eyes, ears, and shoulders.</div>
          <button onClick={() => setPage(1)}>Back</button>
          <button onClick={() => setPage(7)}>Next</button>
          {/* <button onClick={startDemo}>Next</button> */}
        </div>
      )}

      { page === 7 && (
        <div className='content curriculum'>
          <h2>Brick Breakers</h2>
          <div className='description'>
            Brick breakers is a game where the objective is to use a bouncing ball to break all of the bricks on the screen.
            Normally you would use arrow keys or touch screen controls to move your paddle back and forth across the screen,
            but in this project you’ll be controlling it using a skeletal tracking AI that you train to recognize different poses.
            <br/><br/>
            Keep in mind that when you’re training your AI, you’ll want to make sure your poses for moving the paddle left and right are easily identifiable from one another to get the best results.
          </div>
          <div><img className='brick-breaker-img' src={img2} alt='game' /></div>
          <button onClick={() => setPage(2)}>Back</button>
          <button onClick={() => setPage(8)}>Next</button>
        </div>
      )}

      { page === 8 && (
        <div className='content curriculum'>
          <h2>Let's try it out with pre-trained model!</h2>
          <div className='description center'>
            We trained the model with left / neutral / right labels for you.
          </div>
          <div className='row examples'>
            <div className='col-4'>
              <p>Example pose for <b>left</b></p>
              <img alt='left' src={img3} className='left-example' />
            </div>
            <div className='col-4'>
              <p>Example pose for <b>neutral</b></p>
              <img alt='neutral' src={img4} className='neutral-example' />
            </div>
            <div className='col-4'>
              <p>Example pose for <b>right</b></p>
              <img alt='right' src={img3} className='right-example' />
            </div>
          </div>
          <div className='description center'>Before going to train your own model, let's test pre-trained model first.</div>
          <button onClick={() => setPage(7)}>Back</button>
          <button onClick={startDemo}>Next</button>
        </div>
      )}

      { page === 3 && (
        <div className="content page3">
          <h2>Skeletal tracking demo</h2>
          { loading && (
            <h3> Loading ... </h3>
          )}
          <div style={{display: loading? 'none': ''}}>
            <div>
              { webcamDiv }
              {/* { running ? (
                <button onClick={stopRunningModel}>Stop</button>
              ) : (
                <button onClick={startRunningModel}>Start!</button>
              )} */}
              <div className='result row'>
                <div className={label === 'left' ? 'label col-3 current' : 'label col-3'}>left</div>
                <div className='prob col-1'>{leftProb}%</div>
                <div className={label === 'neutral' ? 'label col-3 current' : 'label col-3'}>neutral</div>
                <div className='prob col-1'>{neutralProb}%</div>
                <div className={label === 'right' ? 'label col-3 current' : 'label col-3'}>right</div>
                <div className='prob col-1'>{rightProb}%</div>
              </div>
              <button onClick={goToGamePage}>Start game</button>
              { shareDiv }
            </div>
          </div>
        </div>
      )}
      
      { page === 4 && (
        <div className="page4">
          <canvas id="gameCanvas" width="800" height="600"></canvas>
          { webcamDiv }
          <div className='results'>
            <div className='result row'>
              <div className={label === 'left' ? 'label col-9 current' : 'label col-9'}>left</div>
              <div className='prob col-3'>{leftProb}%</div>
            </div>
            <div className='result row'>
              <div className={label === 'neutral' ? 'label col-9 current' : 'label col-9'}>neutral</div>
              <div className='prob col-3'>{neutralProb}%</div>
            </div>
            <div className='result row'>
              <div className={label === 'right' ? 'label col-9 current' : 'label col-9'}>right</div>
              <div className='prob col-3'>{rightProb}%</div>
            </div>
          </div>
          <div className="speed">
            speed :
            <input type="number" value={speed} onChange={(e) => {
              setSpeed(parseInt(e.target.value));
              brickSpeed = parseInt(e.target.value);
            }}/>
          </div>
          { !usingCustomModel && (
            <button onClick={loading ? null : goToTrainingPage}>Training</button>
          )}
          { usingCustomModel && (
            <button onClick={goToQuiz}>Quiz</button>
          )}
          
          { shareDiv }
        </div>
      )}

      { page === 5 && (
        <div className='content page5'>
          <h2>Capturing Poses</h2>
          <div className='description'>
            Use your webcam to capture images of the poses you want to be used for moving your paddle left and right.
            Start by clicking the Collect button to collect left poses to train your AI and then click the Right button and repeat the same steps.
            Once you’ve collected poses for moving the paddle both left and right, click the Train button to have your AI begin analyzing your images. 
          </div>
          <button onClick={loadWebcamForTrainingPage}>Next</button>
        </div>
      )}

      { page === 9 && ( /* training */ 
        <div className='content page9'>
          <h2>Capturing Poses</h2>
          <div className='description'>
            Click the <b>Take Photo</b> button to begin collecting images of what you want your pose for moving <b>{currentDataLabel}</b> to be.<br/>
            {/* There is a 2 second delay before it captures images for 6 seconds. */}
            It captures images for <b>2 seconds</b>.
          </div>
          <div className='row'>
            <div className='col-4'>
              <div className={currentDataLabel === 'left' ? 'label current': 'label'} onClick={() => setCurrentDataLabel('left')}>Left</div>
              <div className='count'>{numLeftData}</div>
            </div>
            <div className='col-4'>
              <div className={currentDataLabel === 'neutral' ? 'label current': 'label'} onClick={() => setCurrentDataLabel('neutral')}>Neutral</div>
              <div className='count'>{numNeutralData}</div>
            </div>
            <div className='col-4'>
              <div className={currentDataLabel === 'right' ? 'label current': 'label'} onClick={() => setCurrentDataLabel('right')}>Right</div>
              <div className='count'>{numRightData}</div>
            </div>
          </div>
          <div>
            { webcamDiv }
          </div>
          <button className='collect'
            onClick={async(e) => {await handleCollectDataBtn(e)}}
            disabled={areButtonsDisabled}
          >
            Take photo
          </button>
          { currentDataLabel == 'left' && (
            <div className='train'>
              <button
                className={numLeftData !== 0 ? 'right' : 'right disabled'}
                onClick={() => numLeftData !== 0 ? setCurrentDataLabel('right') : null}>Next</button>
            </div>
          )}
          { currentDataLabel !== 'left' && (
            <div className='train'>
              <button className='left' onClick={() => setCurrentDataLabel('left')}>back</button>
              <button
                className={numLeftData !== 0 && numRightData !== 0 ? 'right': 'right disabled'}
                onClick={numLeftData !== 0 && numRightData !== 0 ? train: null}>Train</button>
            </div>
          )}
        </div>
      )}

      { page === 10 && (
        <div className='content page10'>
          {training ? (
            <div>
              <h2><span>Training</span><span>{displayDots}</span></h2>
              <div>It takes around 1-2 minutes.</div>
              <div>Don't leave the tab while training.</div>
            </div>
          ) : (
            <h2>Done!</h2>
          )}
        </div>
      )}

      { page === 6 && (
        <div className='content page6'>
          <h2>Try out own model</h2>
          <p>Please input your sharable link</p>
          <p className='important'>Please make sure to turn off the webcam that used in teachable machine page.</p>
          <input value={customModelLink} onChange={onChangeCustomLink} />
          <button onClick={startDemoWithCustomModel}>Start</button>
        </div>
      )}

      { page === 12 && (
        <div className='content page12'>
          <h2>Quiz</h2>
          <div>
            <div className='question'>{quizs[currentQuizId].id}. {quizs[currentQuizId].question}</div>
            <div className='answers'>
              {quizs[currentQuizId].answers.map((answer, index) => {
                return <div className='answer' key={index}>
                  <div onClick={() => selectAnswer(index, answer)} className={currentAnswer === answer ? 'checkbox checked' : 'checkbox'} />
                  <div className='txt'>{answer}</div>
                </div>
              })}
            </div>
          </div>
          { currentQuizId < quizs.length - 1 ? (
            <button onClick={() => setCurrentQuizId(currentQuizId + 1)}>Next</button>
          ) : (
            <button onClick={submitQuiz}>Submit</button>
          )}
        </div>
      )}

      { page === 13 && (
        <div className='content page13'>
          <div>
            <div className='score'>Score : {score} / {quizs.length}</div>
            <h2>Quiz</h2>
          </div>
          <div>
            <div className='question'>{quizs[currentQuizId].id}. {quizs[currentQuizId].question}</div>
            <div className='answers'>
              {quizs[currentQuizId].answers.map((answer, index) => {
                return <div className={'answer' + 
                  (index === quizs[currentQuizId].correct ? ' correct' : '') +
                  (index !== quizs[currentQuizId].correct && index === savedAnswer[currentQuizId] ? ' wrong' : '')
                } key={index}>
                  <div className='checkbox' />
                  <div className='txt'>{answer}</div>
                </div>
              })}
            </div>
          </div>
          { currentQuizId < quizs.length - 1 && (
            <button onClick={() => setCurrentQuizId(currentQuizId + 1)}>Next</button>
          )}
        </div>
      )}
      
    </div>
  );
}

export default Posenet;
