import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
// import * as automl from '@tensorflow/tfjs-automl';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon, RedditIcon } from "react-share";

import './App.css';

import img1 from './img1.jpg';

const coco_labels = [
  'person', 'bicycle', 'car', 'motorcycle', 'airplane', 'bus', 'train', 'truck', 'boat', 'traffic light', 'fire hydrant',
  'stop sign', 'parking meter', 'bench', 'bird', 'cat', 'dog', 'horse', 'sheep', 'cow', 'elephant', 'bear', 'zebra', 'giraffe',
  'backpack', 'umbrella', 'handbag', 'tie', 'suitcase', 'frisbee', 'skis', 'snowboard', 'sports ball', 'kite', 'baseball bat',
  'baseball glove', 'skateboard', 'surfboard', 'tennis racket', 'bottle', 'wine glass', 'cup', 'fork', 'knife', 'spoon', 'bowl',
  'banana', 'apple', 'sandwich', 'orange', 'broccoli', 'carrot', 'hot dog', 'pizza', 'donut', 'cake', 'chair', 'couch', 'potted plant',
  'bed', 'dining table', 'toilet', 'tv', 'laptop', 'mouse', 'remote', 'keyboard', 'cell phone', 'microwave', 'oven', 'toaster', 'sink',
  'refrigerator', 'book', 'clock', 'vase', 'scissors', 'teddy bear', 'hair drier', 'toothbrush'
];

const shareUrl = `ai.robolink.com`;

const App = () => {
  const [ page, setPage ] = useState(0);
  const [ submittedEmail, setSubmittedEmail ] = useState(false);
  const [ loadFormTimes, setLoadFormTimes ] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ cantLoadWebcam, setCantLoadWebcam ] = useState(false);
  const [ running, setRunning ] = useState(false);
  const [ model, setModel ] = useState(null);
  const [ video , setVideo ] = useState(null);
  const [ canvas, setCanvas ] = useState(null);
  const [ canvas2, setCanvas2 ] = useState(null);
  const [ detectionInterval, setDetectionInterval ] = useState(null);
  const [ showObjectList, setShowObjectList ] = useState(false);

  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    let checkSubmittedEmailInStorage = localStorage.getItem("submittedEmail");
    if (checkSubmittedEmailInStorage) {
      setSubmittedEmail(true);
    }
    else {

    }
  }, []);

  const handleSubmitEmail = () => {
    setLoadFormTimes(loadFormTimes + 1);
    if (loadFormTimes > 0) {
      setTimeout( () => {
        setSubmittedEmail(true);
        localStorage.setItem('submittedEmail', true);
      }, 2000);
    }
  }

  const loadWebcam = async() => {
    try {
      let constraints = { audio: false, video: {
                          width: { min: 320, ideal: 640, max: 640 },
                          height: { min: 240, ideal: 480, max: 480 }
                        }
      };
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(constraints, handleVideo, videoError);
      }
    } catch (e) {
      console.log(e);
      setCantLoadWebcam(true);
    }
  }

  const startDemo = () => {
    setPage(3);
    loadWebcam();
  }

  const handleVideo = async (stream) => {
    const video = document.getElementById('video');
    video.srcObject = stream;
    const canvas = document.getElementById('canvas');
    const canvas2 = document.getElementById('canvas2');
    setVideo(video);
    setCanvas(canvas);
    setCanvas2(canvas2);
    await loadModel();
    // await loadCustomModel();
  }

  const videoError = () => {
    setLoading(false);
    setCantLoadWebcam(true);
  }

  const loadModel = async() => {
    const model = await cocoSsd.load();
    setModel(model);
    setLoading(false);
  }

  const loadCustomModel = async() => {
    // const model = await cocoSsd.load({
    //   base: 'lite_mobilenet_v2',
    //   modelUrl: 'lite_v2_1_1223/model.json'
    // });
    // console.log(model);

    // const model = await automl.loadObjectDetection('model/model.json');
    // console.log(model)

    console.log('load model');
    const model = await tf.loadGraphModel("model/model.json");
    console.log('done');
    console.log(model);
    setModel(model);
    setLoading(false);
  }

  const startDetectingObjects = () => {
    setRunning(true);
    const detectionInterval = setInterval(_detectionInterval, 500);
    setDetectionInterval(detectionInterval);
  }

  const _detectionInterval = async() => {
    model.detect(video).then((predictions) => {
      drawCanvas(predictions);
    });
  }

  const drawCanvas = async(predictions) => {
    const ctx = canvas.getContext('2d');
    const ctx2 = canvas2.getContext('2d');
    canvas.width = 640;
    canvas.height = 480;
    canvas2.width = 640;
    canvas2.height = 480;
    ctx.beginPath();
    ctx2.beginPath();
    ctx.lineWidth = "2";
    ctx.strokeStyle = "#6236FF";
    ctx2.font = "16px Arial";
    for (let prediction of predictions) {
      ctx.rect(...prediction.bbox);
      ctx.stroke();

      ctx.beginPath();
      ctx.rect(prediction.bbox[0], prediction.bbox[1] - 25, 150, 25);
      ctx.lineWidth = "2";
      ctx.fillStyle = "#6236FF";
      ctx.fill();
      ctx.stroke();
      
      ctx2.beginPath();
      ctx2.fillStyle = "#FFFFFF"
      ctx2.fillText(prediction.class, 640 - prediction.bbox[0] + 5 - 145, prediction.bbox[1] - 10);
      ctx2.stroke();
    }
  }


  const stopDetectingObjects = () => {
    clearInterval(detectionInterval);
    const ctx = canvas.getContext('2d');
    const ctx2 = canvas2.getContext('2d');
    canvas.width = 640;
    canvas.height = 480;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx2.clearRect(0, 0, canvas.width, canvas.height);
    setRunning(false);
  }

  return (
    <div className="App">
      { page === 0 && (
        <div className={submittedEmail ? "content page0": "content page0 small"}>
          <header><h1>Robolink AI web app free trial</h1></header>
          <div>
            { submittedEmail ? (
              <div>
                <p>Are you ready to try out our first AI demo?</p>
                <button onClick={() => setPage(1)}>Yes!</button>
              </div>
            ) : (
              <div>
                <p>Hello! Welcome to the Robolink AI web app free trial.</p>
                <p>We are at the very first step, but we are going to show you more AI demo later!</p>
                <p>Before we start, please submit your email address to access free trial AI demo</p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmhBK856H4tkSW-z-3ILxcBHbf47ck96ZyifK4e6X61JfUww/viewform?embedded=true" onLoad={handleSubmitEmail} width="640" height="423" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              </div>
            )}
          </div>
        </div>
      )}

      { page === 1 && (
        <div className="content page1">
          <h2>What is object detection?</h2>
          <div className="description row">
            <div className="col-6">
              Have you ever seen the robot or self driving car detect objects?
              For that, it uses technology called object detection.
              Object detection is a computer technology that detecting instances of objects of a certain class within images or videos.
            </div>
            <div className="col-6"><img src={img1} alt='image classification and object detection'/></div>
          </div>
          <div className="description">
            If you've used one of Robolink product Zumi, you've already tested image classification.
            Image classification is a technology that classifying class in image.
            On top of that, object detection can detect multi classes within one frame and predict location of object.
          </div>
          <button onClick={()=> setPage(2)}>Next</button>
        </div>
      )}

      { page === 2 && (
        <div className="content page2">
          <h2>Object detection demo</h2>
          <div className="description">
            Training object detection model takes a lot of time and needs a lot of data. <br/>
            So let's try pre-trained model.
            This model can detect these objects.
          </div>
          <div className="object-list">
            <div className="row">
              { coco_labels.sort().map( (label, i) => <div className="col-3" key={i}>{label}</div>)}
            </div>
          </div>
          <button onClick={startDemo}>Next</button>
        </div>
      )}

      { page === 3 && (
        <div>
          { !loading && (
            <div className="list" onMouseOver={() => setShowObjectList(true)} onMouseLeave={() => setShowObjectList(false)}>
              <div className="list-btn">Object list<div className="triangle"></div></div>
              <div className="object-list" style={{display: showObjectList? "": "none"}}>
                <div className="row">
                  { coco_labels.sort().map( (label, i) => <div className="col-12" key={i}>{label}</div>)}
                </div>
              </div>
            </div>
          )}
          
          <div className="content page3">
            <h2>Object detection demo</h2>
            { loading && (
              <h3> Loading ... </h3>
            )}
            <div style={{display: loading? 'none': ''}}>
              { cantLoadWebcam ? (
                <div>Please allow to use webcam</div>
              ) : (
                <div>
                  <div className='webcam' id='webcam'>
                    <video id='video' autoPlay={true} />
                    <canvas id='canvas'></canvas>
                    <canvas id='canvas2'></canvas>
                  </div>
                  { running ? (
                    <button onClick={stopDetectingObjects}>Stop</button>
                  ) : (
                    <button onClick={startDetectingObjects}>Start!</button>
                  )}
                  <div className="share-icons">
                    <FacebookShareButton url={shareUrl} className="icon">
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} className="icon">
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl} className="icon">
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <WhatsappShareButton url={shareUrl} className="icon">
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <RedditShareButton url={shareUrl} className="icon">
                      <RedditIcon size={32} round />
                    </RedditShareButton>
                    <EmailShareButton url={shareUrl} className="icon">
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        
      )}
      
      
    </div>
  );
}

export default App;
