import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga';

import * as tf from '@tensorflow/tfjs';
import * as tfd from '@tensorflow/tfjs-data';
import * as faceapi from '@vladmandic/face-api';
import * as tmImage from '@teachablemachine/image';

// import Blocky from 'blockly';
import * as Blockly from 'blockly/core';

import BlocklyComponent, { Block, Value, Field, Shadow } from './Blockly';

import BlocklyJS from 'blockly/javascript';

import './blocks/customblocks';
import './generator/generator';

import { MaskContext } from './contexts/MaskContext.js';

import './App.css';
import './Face.scss';
import './FaceWithBlockly.scss';

let modelPath = `/weights`;
const maskModelPath = '/mask-model';

let video;
let canvas;
let ctx;
let displaySize = { width: 640, height: 480 };
let demoInterval;

let customModel;
let metaData = {"tfjsVersion":"1.3.1","tmVersion":"2.3.1","packageVersion":"0.8.4","packageName":"@teachablemachine/image","timeStamp":"2021-02-11T09:02:26.705Z","userMetadata":{},"modelName":"tm-my-image-model","labels":["mask","no_mask"]};

let isPretrained = false;
let pretrainedModel;

/* Blockly variable */
let myInterpreter = null;
let runner;
let latestCode = '';

// global.Interpreter.PREDICTING = true;
// global.Interpreter.DETECTION;
// global.Interpreter.EXTRACTEDFACE;
// global.Interpreter.LABEL = '';
let predicting = true;
let detection;
let extractedFace;
let label = '';


/* blockly function */
// function initApi(interpreter, globalObject) {
//   // Add an API for the wait block.  See wait_block.js
//   initInterpreterWaitForSeconds(interpreter, globalObject);
// }

function initInterpreterWaitForSeconds(interpreter, globalObject) {
  // Ensure function name does not conflict with variable names.
  Blockly.JavaScript.addReservedWords('waitForSeconds');

  var wrapper = interpreter.createAsyncFunction(
    function(timeInSeconds, callback) {
      // Delay the call to the callback.
      setTimeout(callback, timeInSeconds * 1000);
    });
  interpreter.setProperty(globalObject, 'waitForSeconds', wrapper);
}

const FaceWithBlockly = (props) => {
  const { userMaskModel } = useContext(MaskContext);

  const [ loading, setLoading ] = useState(true);
  const [ cantLoadWebcam, setCantLoadWebcam ] = useState(false);
  const [ running, setRunning ] = useState(false);

  // const [predicting, setPredicting] = useState(false);
  // const [ label, setLabel ] = useState('');
  const [ maskProb, setMaskProb ] = useState(0);
  const [ nomaskProb, setNomaskProb ] = useState(0);

  let simpleWorkspace = React.createRef();
 
  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // console.log(global.Interpreter);
    // function scriptLoaded() {
    //   window.Interpreter.sort();
    // }

    // const script = document.createElement("script");
    // script.src = "/js/acorn_interpreter.js";
    // script.async = true;
    // script.onload = () => {
    //   try {
    //     console.log(Interpreter);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // document.body.appendChild(script);
    // console.log(script);
    

    isPretrained = true;
    loadWebcam();

    return () => {
      // document.body.removeChild(script);
    }
  }, []);

  const loadWebcam = async() => {
    try {
      let constraints = { audio: false, video: {
                          width: { min: 320, ideal: 640, max: 640 },
                          height: { min: 240, ideal: 480, max: 480 }
                        }
      };
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(constraints, handleVideo, videoError);
      }
    } catch (e) {
      console.log(e);
      setCantLoadWebcam(true);
    }
  }

  const handleVideo = async (stream) => {
    video = document.getElementById('faceVideo');
    video.srcObject = stream;
    canvas = document.getElementById('faceCanvas');
    ctx = canvas.getContext('2d');
    faceapi.matchDimensions(canvas, displaySize);

    await loadFaceModel();
    if (userMaskModel !== null) {
      pretrainedModel = userMaskModel;
      setLoading(false);
      return;
    }
    else if (isPretrained) await loadPretrainedModel();
    else await loadMobileNet();
  }

  const videoError = () => {
    setLoading(false);
    setCantLoadWebcam(true);
  }

  const loadFaceModel = async() => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(modelPath);
    await faceapi.nets.faceLandmark68Net.loadFromUri(modelPath);
    await faceapi.detectSingleFace(video).withFaceLandmarks();
    console.log('loaded face model');
  }

  const loadMobileNet = async() => {
    // controller = new ControllerDataset(NUM_CLASSES);
    customModel = await tmImage.createTeachable(metaData);
    customModel.prepareDataset();
    // console.log(customModel);
  }

  const loadPretrainedModel = async() => {
    pretrainedModel = await tmImage.load(maskModelPath + '/mymodel.json', metaData);
    console.log('loaded pretrained model');
    setLoading(false);
  }

  // const detectFace = async() => {
  //   const detection = await faceapi.detectSingleFace(video).withFaceLandmarks();
  //   if(detection === undefined) {
  //     // console.log('no face');
  //     return;
  //   }
    // const rect = detection.alignedRect.box;
    // const regionsToExtract = [
    //   new faceapi.Rect(rect._x, rect._y, rect._width, rect._height)
    // ];
    // const extractedFace = await faceapi.extractFaces(video, regionsToExtract);
    // ctx.clearRect(0, 0, 640, 480);
    // ctx.beginPath();
    // ctx.rect(rect._x, rect._y, rect._width, rect._height);
    // ctx.stroke();
  //   // console.log(extractedFace[0]); // <canvas />
  //   // console.log(typeof extractedFace[0]); // Object
  //   return extractedFace[0];
  // }


  /* Blockly function */
  const detectFace = async() => {
    detection = await faceapi.detectSingleFace(video).withFaceLandmarks();
    // console.log(detection);
  }

  const drawFace = async(i) => {
    // console.log(i);
    if(detection === undefined) {
      console.log('error!');
      return;
    }
    const rect = detection.alignedRect.box;
    const regionsToExtract = [
      new faceapi.Rect(rect._x, rect._y, rect._width, rect._height)
    ];
    extractedFace = await faceapi.extractFaces(video, regionsToExtract);
    ctx.clearRect(0, 0, 640, 480);
    ctx.beginPath();
    ctx.lineWidth = '6';
    ctx.strokeStyle = 'blue';
    ctx.rect(rect._x, rect._y, rect._width, rect._height);
    ctx.stroke();
  }

  const resizeImage = async(beforeC) => {
    let beforeCtx = beforeC.getContext('2d');    
    let resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 224;
    resizedCanvas.height= 224;
    let resizedCtx = resizedCanvas.getContext('2d');
    resizedCtx.drawImage(beforeCtx.canvas, 0, 0, 224, 224);
    return resizedCanvas;
  }

  const predict = async() => {
    if(extractedFace === undefined || extractedFace[0] === undefined) {
      console.log('error!');
      return;
    }
    let resizedFace = await resizeImage(extractedFace[0]);
    let prediction = await pretrainedModel.predict(resizedFace, false);
    // console.log(prediction);

    // console.log(prediction[0]);
    let mask = prediction[0].probability;
    let nomask = prediction[1].probability;
    // console.log(mask);
    // console.log(nomask);

    label = mask > nomask ? "mask" : "no mask";
    // setLabel(label);
    // setMaskProb(mask);
    // setNomaskProb(nomask);
    // console.log(label);
    // console.log(mask);
    // console.log(nomask);
  }

  const printLabel = () => {
    console.log(label);
    let div = document.getElementById('label');
    div.innerText = label;
    // let p = document.createElement('p');
    // p.innerText = label;
    // div.appendChild(p);
  }

  const save = async() => {
    const saveResult = await customModel.save('downloads://mymodel');
    // console.log(saveResult);
  }


  /* blockly */ 
  const resetInterpreter = () => {
    myInterpreter = null;
    if (runner) {
      clearTimeout(runner);
      runner = null;
    }
  }

  function initApi(interpreter, globalObject) {
    // // Add an API function for the alert() block, generated for "text_print" blocks.
    // var wrapper = function(text) {
    //   text = text ? text.toString() : '';
    //   outputArea.value = outputArea.value + '\n' + text;
    // };
    // interpreter.setProperty(globalObject, 'alert',
    //     interpreter.createNativeFunction(wrapper));

    // // Add an API function for the prompt() block.
    // var wrapper = function(text) {
    //   text = text ? text.toString() : '';
    //   return interpreter.createPrimitive(prompt(text));
    // };
    // interpreter.setProperty(globalObject, 'prompt',
    //     interpreter.createNativeFunction(wrapper));

    // Add an API for the wait block.  See wait_block.js
    initInterpreterWaitForSeconds(interpreter, globalObject);

    var wrapper = function() {
      return interpreter.createPrimitive(detectFace());
    }
    interpreter.setProperty(globalObject, 'detectFace',
          interpreter.createNativeFunction(wrapper));

    var wrapper = function() {
      return interpreter.createPrimitive(drawFace());
    }
    interpreter.setProperty(globalObject, 'drawFace',
          interpreter.createNativeFunction(wrapper));

    var wrapper = function() {
      return interpreter.createPrimitive(predict());
    }
    interpreter.setProperty(globalObject, 'predict',
          interpreter.createNativeFunction(wrapper));

    var wrapper = function() {
      return interpreter.createPrimitive(printLabel());
    }
    interpreter.setProperty(globalObject, 'printLabel',
          interpreter.createNativeFunction(wrapper));

    // // Add an API function for highlighting blocks.
    // var wrapper = function(id) {
    //   id = id ? id.toString() : '';
    //   return interpreter.createPrimitive(highlightBlock(id));
    // };
    // interpreter.setProperty(globalObject, 'highlightBlock',
    //     interpreter.createNativeFunction(wrapper));
  }

  const generateCode = () => {
    latestCode = BlocklyJS.workspaceToCode(
      simpleWorkspace.current.workspace
    );
    console.log(latestCode);

    if (!myInterpreter) {
      setTimeout(function() {
        myInterpreter = new global.Interpreter(latestCode, initApi);
        runner = function() {
          if (myInterpreter) {
            var hasMore = myInterpreter.run();
            if (hasMore) {
              // Execution is currently blocked by some async call.
              // Try again later.
              setTimeout(runner, 10);
            } else {
              // Program is complete.
              resetInterpreter();
            }
          }
        };
        runner();
      }, 1);
      return;
    }

    // eval(code);
  }

  return (
      <div className='FaceWithBlockly'>
        { loading && (
          <div className='loadingOverlay'>
            <div>Loading model... Please wait</div>
          </div>
        )}
        <button onClick={generateCode}>Run</button>
        <div>
        <BlocklyComponent ref={simpleWorkspace}
          readOnly={false} trashcan={true} media={'media/'}
          move={{
            scrollbars: true,
            drag: true,
            wheel: true
          }}
          initialXml={`
                      <xml xmlns="http://www.w3.org/1999/xhtml">
                      <block type="controls_repeat_ext"><value name="TIMES"><shadow type="math_number"><field name="NUM">10</field></shadow></value></block>
                      </xml>
                    `}>
            <Block type="controls_repeat_ext">
              <Value name="TIMES">
                <Shadow type="math_number">
                  <Field name="NUM">10</Field>
                </Shadow>
              </Value>
            </Block>
            <Block type="wait_seconds" />
            <Block type="detect_face" />
            <Block type="draw_face" />
            <Block type="predict_wearing_mask" />
            <Block type="print_results" />
            <Block type="controls_if" />
            <Block type="logic_compare" />
            <Block type="logic_operation" />
            <Block type="logic_operation" />
            <Block type="logic_negate" />
            <Block type="logic_boolean" />
            <Block type="logic_null" disabled="true" />
            <Block type="logic_ternary" />
            <Block type="text_charAt">
              <Value name="VALUE">
                <Block type="variables_get">
                  <Field name="VAR">text</Field>
                </Block>
              </Value>
            </Block>
          </BlocklyComponent>
        </div>

        <div id='label'></div>
        <div className='faceDiv'>
          <video id='faceVideo' autoPlay={true}></video>
          <canvas id='faceCanvas'></canvas>
          <div id='testdrawing'></div>
        </div>
      </div>
  );
}

export default FaceWithBlockly;