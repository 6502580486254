import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import MaskContextProvider, { MaskContext } from './contexts/MaskContext';
ReactDOM.render(
  <React.StrictMode>
    <MaskContextProvider>
      <Main />
    </MaskContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
