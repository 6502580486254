import React, { createContext, useState, useEffect } from 'react';

export const MaskContext = createContext();

const MaskContextProvider = (props) => {
  const [userMaskModel, setUserMaskModel] = useState(null);

  useEffect(() => {
    console.log(userMaskModel);
  }, [userMaskModel])

  return (
    <MaskContext.Provider value={{ userMaskModel, setUserMaskModel }}>
      {props.children}
    </MaskContext.Provider>
  )
}

export default MaskContextProvider;
