/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define generation methods for custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on generating code:
// https://developers.google.com/blockly/guides/create-custom-blocks/generating-code

import * as Blockly from 'blockly/core';
import 'blockly/javascript';

Blockly.JavaScript['test_react_field'] = function (block) {
    return 'console.log(\'custom block\');\n';
};

Blockly.JavaScript['test_react_date_field'] = function (block) {
    return 'console.log(' + block.getField('DATE').getText() + ');\n';
    // return 'test()';
};

Blockly.JavaScript['while_loop'] = function(block) {
  var value_while = Blockly.JavaScript.valueToCode(block, 'while', Blockly.JavaScript.ORDER_ATOMIC);
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
  // TODO: Assemble JavaScript into code variable.
  // console.log(value_while);
  // console.log(statements_do);
  // var code = `while${value_while} {
  //   setTimeout(function() {
  //     console.log('before start');
  //     ${statements_do}
  //   }, 2000);
  // }`
  // var code = `while${value_while} {
  //   const runCode = async() => {
  //     ${statements_do}
  //     // console.log('run code');
  //   }
  //   console.log('test2');
  //   runCode();
  // }`
  var code = `while${value_while} {
    console.log('test2');
    waitForSeconds(1);
    ${statements_do}
  }`
  return code;
};

Blockly.JavaScript['is_predicting'] = function(block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'predicting === true';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['detect_face'] = function(block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'detectFace();\n';
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.JavaScript['draw_face'] = function(block) {
  var dropdown_face = block.getFieldValue('face');
  // TODO: Assemble JavaScript into code variable.
  var code = `drawFace(${dropdown_face});\n`;
  return code;
};

Blockly.JavaScript['predict_wearing_mask'] = function(block) {
  var dropdown_face = block.getFieldValue('face');
  // TODO: Assemble JavaScript into code variable.
  var code = `predict(${dropdown_face});\n`;
  return code;
};

Blockly.JavaScript['print_results'] = function(block) {
  var dropdown_label = block.getFieldValue('label');
  // TODO: Assemble JavaScript into code variable.
  // return `console.log(${dropdown_label});\n`;
  // var code = `printLabel(${dropdown_label});\n`;
  var code = `printLabel();\n`;
  return code;
};

Blockly.JavaScript['wait_seconds'] = function(block) {
  var seconds = Number(block.getFieldValue('SECONDS'));
  var code = 'waitForSeconds(' + seconds + ');\n';
  return code;
};

function initInterpreterWaitForSeconds(interpreter, globalObject) {
  // Ensure function name does not conflict with variable names.
  Blockly.JavaScript.addReservedWords('waitForSeconds');

  var wrapper = interpreter.createAsyncFunction(
    function(timeInSeconds, callback) {
      // Delay the call to the callback.
      setTimeout(callback, timeInSeconds * 1000);
    });
  interpreter.setProperty(globalObject, 'waitForSeconds', wrapper);
}