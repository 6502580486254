import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import MainPage from './MainPage';
import Camera from './Camera';
import App from './App';
import Posenet from './Posenet';
import Face from './Face';
import FaceWithBlockly from './FaceWithBlockly';
import Mask from './Mask';

const Main = (props) => {
  return (
    <div>
      <BrowserRouter>
        <Route exact path='/' component={MainPage} />
        <Route path='/camera' component={Camera} />
        <Route path='/object-detection' component={App} />
        <Route path='/skeletal-tracking' component={Posenet} />
        <Route path='/face-with-blockly' component={FaceWithBlockly} />
        <Route path='/facial-expression-recognition' component={Face} />
        <Route path='/mask-detection' component={Mask} />
      </BrowserRouter>
    </div>
  )
}

export default Main;
