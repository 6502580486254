import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import * as tf from '@tensorflow/tfjs';
import * as faceapi from '@vladmandic/face-api';

import './App.css';
import './Face.scss';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon, RedditIcon } from "react-share";

let modelPath = `/weights`;
const shareUrl = `ai.robolink.com`;

let video;
let canvas;
let ctx;
let displaySize = { width: 640, height: 480 };
let demoInterval;

const Face = () => {
  const [ page, setPage ] = useState(0);
  const [ submittedEmail, setSubmittedEmail ] = useState(false);
  const [ loadFormTimes, setLoadFormTimes ] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ cantLoadWebcam, setCantLoadWebcam ] = useState(false);
  const [ running, setRunning ] = useState(false);
  const [ expressions, setExpressions ] = useState({angry: 0, disgusted: 0, fearful: 0, happy: 0, neutral: 0, sad: 0, surprised: 0});
  const [ currentExpression, setCurrentExpression ] = useState('neutral');
 
  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    let checkSubmittedEmailInStorage = localStorage.getItem("submittedEmail");
    if (checkSubmittedEmailInStorage) {
      setSubmittedEmail(true);
    }
  }, []);

  const handleSubmitEmail = () => {
    setLoadFormTimes(loadFormTimes + 1);
    if (loadFormTimes > 0) {
      setTimeout( () => {
        setSubmittedEmail(true);
        localStorage.setItem('submittedEmail', true);
      }, 2000);
    }
  }

  const goToDemoPage = () => {
    setPage(1);
    loadWebcam();
  }

  const loadWebcam = async() => {
    try {
      let constraints = { audio: false, video: {
                          width: { min: 320, ideal: 640, max: 640 },
                          height: { min: 240, ideal: 480, max: 480 }
                        }
      };
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(constraints, handleVideo, videoError);
      }
    } catch (e) {
      console.log(e);
      setCantLoadWebcam(true);
    }
  }

  const handleVideo = async (stream) => {
    video = document.getElementById('faceVideo');
    video.srcObject = stream;
    canvas = document.getElementById('faceCanvas');
    ctx = canvas.getContext('2d');
    faceapi.matchDimensions(canvas, displaySize);

    await loadModel();
  }

  const videoError = () => {
    setLoading(false);
    setCantLoadWebcam(true);
  }

  const loadModel = async() => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(modelPath);
    await faceapi.nets.faceLandmark68Net.loadFromUri(modelPath);
    await faceapi.nets.faceExpressionNet.loadFromUri(modelPath);
    setLoading(false);
  }

  const startDemo = () => {
    demoInterval = setInterval(() => {
      detectFaces();
    }, 500);
    setRunning(true);
  }

  const stopDemo = () => {
    clearInterval(demoInterval);
    setTimeout(() => {
      ctx.clearRect(0, 0, 640, 480);
    }, 1000);
    setRunning(false);
  }

  const detectFaces = async() => {
    // const detection = await faceapi.detectAllFaces(video);
    // console.log(detection);
    const detectionsWithExpressions = await faceapi.detectAllFaces(video).withFaceLandmarks().withFaceExpressions();
    // console.log(detectionsWithExpressions);
    // console.log(displaySize);
    const resizedDetectionsWithExpressions = await faceapi.resizeResults(detectionsWithExpressions, displaySize);
    ctx.clearRect(0, 0, 640, 480);
    if(resizedDetectionsWithExpressions.length <= 0) return;
    const box = resizedDetectionsWithExpressions[0].alignedRect.box;
    const drawBox = new faceapi.draw.DrawBox(box);
    drawBox.draw(canvas);
    faceapi.draw.drawFaceLandmarks(canvas, resizedDetectionsWithExpressions);
    // const minProbability = 0.05;
    // faceapi.draw.drawFaceExpressions(canvas, resizedDetectionsWithExpressions, minProbability);
    const exps = resizedDetectionsWithExpressions[0].expressions;
    setExpressions(exps);
    bestMatchExpression(exps);
  }

  const bestMatchExpression = async(exp) => {
    const best = Object.keys(exp).reduce((a, b) => exp[a] > exp[b] ? a : b);
    setCurrentExpression(best);
  }

  const shareDiv = (
    <div className="share-icons">
      <FacebookShareButton url={shareUrl} className="icon">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="icon">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} className="icon">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={shareUrl} className="icon">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <RedditShareButton url={shareUrl} className="icon">
        <RedditIcon size={32} round />
      </RedditShareButton>
      <EmailShareButton url={shareUrl} className="icon">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );

  return (
    <div className="Emotion">
      { page === 0 && (
        <div className={submittedEmail ? "content page0": "content page0 small"}>
          <header><h1>Robolink AI web app free trial</h1></header>
          <div>
            { submittedEmail ? (
              <div>
                <p>Are you ready to try out our first AI demo?</p>
                <button onClick={goToDemoPage}>Yes!</button>
              </div>
            ) : (
              <div>
                <p>Hello! Welcome to the Robolink AI web app free trial.</p>
                <p>We are at the very first step, but we are going to show you more AI demo later!</p>
                <p>Before we start, please submit your email address to access free trial AI demo</p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmhBK856H4tkSW-z-3ILxcBHbf47ck96ZyifK4e6X61JfUww/viewform?embedded=true" onLoad={handleSubmitEmail} width="640" height="423" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              </div>
            )}
          </div>
        </div>
      )}
      { page === 1 && (
        <div className='page1 content'>
          <h2>Facial expression recognition</h2>
          <div>
            <video id='faceVideo' autoPlay={true}></video>
            <canvas id='faceCanvas'></canvas>
            { running && (
              <div className='result'>
                <div className='expression'>
                  <div className={currentExpression === 'angry' ? 'label current' : 'label'}>angry</div>
                  <div className='prob'>{(expressions.angry * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'disgusted' ? 'label current' : 'label'}>disgusted</div>
                  <div className='prob'>{(expressions.disgusted * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'fearful' ? 'label current' : 'label'}>fearful</div>
                  <div className='prob'>{(expressions.fearful * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'happy' ? 'label current' : 'label'}>happy</div>
                  <div className='prob'>{(expressions.happy * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'neutral' ? 'label current' : 'label'}>neutral</div>
                  <div className='prob'>{(expressions.neutral * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'sad' ? 'label current' : 'label'}>sad</div>
                  <div className='prob'>{(expressions.sad * 100).toFixed(0)}%</div>
                </div>
                <div className='expression'>
                  <div className={currentExpression === 'surprised' ? 'label current' : 'label'}>surprised</div>
                  <div className='prob'>{(expressions.surprised * 100).toFixed(0)}%</div>
                </div>
              </div>
            )}
          </div>
          { loading ? (
            <p>Loading model...</p>
          ) : (
            running ? (
              <button onClick={stopDemo}>Stop</button>
            ) : (
              <button onClick={startDemo}>Start</button>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default Face;