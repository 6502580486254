import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';

import './MainPage.scss';
import './CameraContainer.scss'

const demoList = ['Object Detection', 'Skeletal Tracking', 'Facial Expression Recognition', "Mask Detection"];

const Camera = (props) => {
  let history = useHistory();

  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const goToDemoPage = (name) => {
    if (name === demoList[0]) history.push('/object-detection');
    else if (name === demoList[1]) history.push('/skeletal-tracking');
    else if (name === demoList[2]) history.push('/facial-expression-recognition');
    else if (name === demoList[3]) history.push('/mask-detection');
    else return;
  }

  return (
    <div className='MainPage Camera container'>
      <h1>Camera(Image)</h1>
      <div className='row'>
        {demoList.map((name, i) => {
          return <div className='type' key={i} onClick={() => goToDemoPage(name)}>
            <div className='title'>{name}</div>
          </div>
        })}
      </div>
    </div>
  )
}

export default Camera;
