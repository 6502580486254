/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks


import * as Blockly from 'blockly/core';

// Since we're using json to initialize the field, we'll need to import it.
import '../fields/BlocklyReactField';
import '../fields/DateField';

var testReactField = {
  "type": "test_react_field",
  "message0": "custom field %1",
  "args0": [
    {
      "type": "field_react_component",
      "name": "FIELD",
      "text": "Click me"
    },
  ],
  "previousStatement": null,
  "nextStatement": null,
};

Blockly.Blocks['test_react_field'] = {
  init: function() {
    this.jsonInit(testReactField);
    this.setStyle('loop_blocks');
  }
};

var reactDateField = {
  "type": "test_react_date_field",
  "message0": "date field %1",
  "args0": [
    {
      "type": "field_react_date",
      "name": "DATE",
      "date": "01/01/2020"
    },
  ],
  "previousStatement": null,
  "nextStatement": null,
};

Blockly.Blocks['test_react_date_field'] = {
  init: function() {
    this.jsonInit(reactDateField);
    this.setStyle('loop_blocks');
  }
};

var whileLoopField = {
  "type": "while_loop",
  "message0": "while %1 do %2",
  "args0": [
    {
      "type": "input_value",
      "name": "while"
    },
    {
      "type": "input_statement",
      "name": "do"
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "colour": 230,
  "tooltip": "",
  "helpUrl": ""
}

Blockly.Blocks['while_loop'] = {
  init: function() {
    this.jsonInit(whileLoopField);
  }
};

var whileLoopField2 = {
  "type": "while_loop_2",
  "message0": "while %1 is %2 %3 do %4",
  "args0": [
    {
      "type": "field_variable",
      "name": "predicting",
      "variable": "predicting"
    },
    {
      "type": "field_dropdown",
      "name": "isTrue",
      "options": [
        [
          "true",
          "T"
        ],
        [
          "false",
          "F"
        ]
      ]
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "do"
    }
  ],
  "colour": 230,
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['while_loop_2'] = {
  init: function() {
    this.jsonInit(whileLoopField2);
  }
};

var isPredictingField = {
  "type": "is_predicting",
  "message0": "It is predicting",
  "output": null,
  "colour": 0,
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['is_predicting'] = {
  init: function() {
    this.jsonInit(isPredictingField);
  }
};

var detectFaceField = {
  "type": "detect_face",
  "message0": "detect face",
  "previousStatement": null,
  "nextStatement": null,
  "colour": 90,
  "tooltip": "",
  "helpUrl": ""
};


Blockly.Blocks['detect_face'] = {
  init: function() {
    this.jsonInit(detectFaceField);
  }
};

var drawFaceField = {
  "type": "draw_face",
  "message0": "draw face %1",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "face",
      "options": [
        [
          "0",
          "0"
        ]
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": 90,
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['draw_face'] = {
  init: function() {
    this.jsonInit(drawFaceField);
  }
};

var predictingWearingMaskField = {
  "type": "predict_wearing_mask",
  "message0": "predict wearing mask on face %1",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "face",
      "options": [
        [
          "0",
          "0"
        ]
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": 90,
  "tooltip": "",
  "helpUrl": ""
}

Blockly.Blocks['predict_wearing_mask'] = {
  init: function() {
    this.jsonInit(predictingWearingMaskField);
  }
};

var printResultsField = {
  "type": "print_results",
  "message0": "print %1",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "label",
      "options": [
        [
          "label",
          "label"
        ],
        [
          "probability",
          "probability"
        ]
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": 90,
  "tooltip": "",
  "helpUrl": ""
};

Blockly.Blocks['print_results'] = {
  init: function() {
    this.jsonInit(printResultsField);
  }
};

Blockly.defineBlocksWithJsonArray([{
  "type": "wait_seconds",
  "message0": " wait %1 seconds",
  "args0": [{
    "type": "field_number",
    "name": "SECONDS",
    "min": 0,
    "max": 600,
    "value": 1
  }],
  "previousStatement": null,
  "nextStatement": null,
  "colour": "%{BKY_LOOPS_HUE}"
}]);

// print predicting (label / probability)