export var ballX = 75;
export var ballY = 75;
export var ballSpeedX = 5;
export var ballSpeedY = 7;

export const BRICK_W = 80;
export const BRICK_H = 20; // temporarily doubled
export const BRICK_GAP = 2;
export const BRICK_COLS = 10;
export const BRICK_ROWS = 14;
export var brickGrid = new Array(BRICK_COLS * BRICK_ROWS);
export var bricksLeft = 0;


export const PADDLE_WIDTH = 100;
export const PADDLE_THICKNESS = 10;
export const PADDLE_DIST_FROM_EDGE = 60;
export var paddleX = 400;

export var canvas, canvasContext;

export var mouseX = 0;
export var mouseY = 0;

export var updateInterval;

// export function updateMousePos(evt){
//   var rect = canvas.getBoundingClientRect();
//   var root = document.documentElement;
  
//   mouseX = evt.clientX - rect.left - root.scrollLeft;
//   mouseY = evt.clientY - rect.top - root.scrollTop;
  
//   paddleX = mouseX - PADDLE_WIDTH/2;
  
//   // cheat / hack to test ball
//   /*ballX = mouseX;
//   ballY = mouseY;
//   ballSpeedX = 4;
//   ballSpeedY = -4;*/
// }

export function updatePosePos(label, prob, speed) {
  var rect = canvas.getBoundingClientRect();
  var root = document.documentElement;


  if(prob >= 0.8){
    speed = speed * 1.25
  }
  else if(prob <= 0.6 && prob > 0.5) {
    speed = speed * 0.5
  }

  if (label === "left") {
    paddleX = paddleX - speed > 0 ? paddleX - speed : 0;
  } else if (label === "right") {
    paddleX = paddleX + speed <= canvas.width - PADDLE_WIDTH ? paddleX + speed : canvas.width - PADDLE_WIDTH;
  }
}

export function brickReset(){
  bricksLeft = 0;
  var i;
  for(i=0; i<3*BRICK_COLS; i++){
    brickGrid[i] = false;
  }
  for(; i<BRICK_COLS*BRICK_ROWS; i++){
    brickGrid[i] = true;
    bricksLeft++;
  } // end of for each brick
} // end of brickReset func

// window.onload = function(){
//   canvas = document.getElementById('gameCanvas');
//   canvasContext = canvas.getContext('2d');
  
//   var framesPerSecond = 30;
//   setInterval(updateAll, 1000/framesPerSecond);
  
//   canvas.addEventListener('mousemove', updateMousePos);
  
//   brickReset();
//   ballReset();
// }

export function onLoadBrickBreaker(c) {
  canvas = c;
  canvasContext = canvas.getContext('2d');
  
  var framesPerSecond = 30;
  updateInterval = setInterval(updateAll, 1000/framesPerSecond);
  // canvas.addEventListener('mousemove', updateMousePos);
  
  brickReset();
  ballReset();
}

export function updateAll(){
  moveAll();
  drawAll();
}

export function ballReset(){
  ballX = canvas.width/2;
  ballY = canvas.height/2;
}

export function ballMove(){
  ballX += ballSpeedX;
  ballY += ballSpeedY;
  
  if (ballX < 0 && ballSpeedX < 0.0){ // left
    ballSpeedX *= -1;
  }
  if (ballX > canvas.width && ballSpeedX > 0.0){ // right
    ballSpeedX *= -1;
  }
  if (ballY < 0 && ballSpeedY < 0.0){ // top
    ballSpeedY *= -1;;
  }
  if (ballY > canvas.height){ //bottom
    ballReset();
    brickReset();
  }
}

export function isBrickAtColRow(col,row){
  if(col >= 0 && col < BRICK_COLS &&
     row >= 0 && row < BRICK_ROWS) {
    var brickIndexUnderCoord = rowColToArrayIndex(col, row);
    return brickGrid[brickIndexUnderCoord];     
  }
  else {
    return false;
  }
}

export function ballBrickHandling(){
  var ballBrickCol = Math.floor(ballX / BRICK_W);
  var ballBrickRow = Math.floor(ballY / BRICK_H);
  var brickIndexUnderBall = rowColToArrayIndex(ballBrickCol,ballBrickRow);
  
  if (ballBrickCol >= 0 && ballBrickCol < BRICK_COLS && 
      ballBrickRow >= 0 && ballBrickRow < BRICK_ROWS){
        
    if(isBrickAtColRow(ballBrickCol, ballBrickRow)){
      brickGrid[brickIndexUnderBall] = false;
      bricksLeft--;
      //console.log(bricksLeft);
      
      var prevBallX = ballX - ballSpeedX;
      var prevBallY = ballY - ballSpeedY;
      var prevBrickCol = Math.floor(prevBallX / BRICK_W);
      var prevBrickRow = Math.floor(prevBallY / BRICK_H);
      
      var bothTestsFailed = true;
      
      if(prevBrickCol != ballBrickCol) {
        if(isBrickAtColRow(prevBrickCol, ballBrickRow) == false){
          ballSpeedX *= -1;
          bothTestsFailed = false;
        }
      }
      if(prevBrickRow != ballBrickRow) {
        if(isBrickAtColRow(ballBrickCol, prevBrickRow) == false){
          ballSpeedY *= -1;
          bothTestsFailed = false;
        }
        
        if (bothTestsFailed) { // armpit case, prevents ball from going through
          ballSpeedX *= -1;
          ballSpeedY *= -1;
        }
      }
      
    } // end of brick found
  } // end of valid col and row
} // end of ballBrickHandling func

export function ballPaddleHandling(){
  var paddleTopEdgeY = canvas.height-PADDLE_DIST_FROM_EDGE;
  var paddleBottomEdgeY = paddleTopEdgeY + PADDLE_THICKNESS;
  var paddleLeftEdgeX = paddleX;
  var paddleRightEdgeX = paddleLeftEdgeX + PADDLE_WIDTH;
  
  if (ballY > paddleTopEdgeY && // below the top of paddle
      ballY < paddleBottomEdgeY && // above bottom of paddle
      ballX > paddleLeftEdgeX && // right of the left side of paddle
      ballX < paddleRightEdgeX){ // left of the right side of paddle
    
    ballSpeedY *= -1;
    
    var centerOfPaddleX = paddleX + PADDLE_WIDTH/2;
    var ballDistFromPaddleCenterX = ballX - centerOfPaddleX;
    ballSpeedX = ballDistFromPaddleCenterX * 0.35;
    
    if(bricksLeft == 0){
      brickReset();
    } // out of bricks
  } // ball center inside paddle
} // end of ballPaddleHandling

export function moveAll(){
  ballMove();
  
  ballBrickHandling();
  
  ballPaddleHandling();
}
export function rowColToArrayIndex(col,row){
  return col + BRICK_COLS * row;
}

export function drawBricks(){
  for(var eachRow=0; eachRow<BRICK_ROWS; eachRow++){
    for(var eachCol=0;eachCol<BRICK_COLS;eachCol++){
      
      var arrayIndex = rowColToArrayIndex(eachCol, eachRow);
      
      if(brickGrid[arrayIndex]){
        colorRect(BRICK_W*eachCol,BRICK_H*eachRow,
                  BRICK_W-BRICK_GAP,BRICK_H-BRICK_GAP,'#73b276');
      } // end of is this brick here
    } // end of for each brick
  }
} // end of drawBricks func

export function drawAll(){
  colorRect(0,0, canvas.width,canvas.height,'#343a40'); //clear screen
  
  colorCircle(ballX,ballY, 10,'#73b276'); //draw ball
  
  colorRect(paddleX, canvas.height-PADDLE_DIST_FROM_EDGE,
            PADDLE_WIDTH,PADDLE_THICKNESS,'#73b276');
            
  drawBricks();
}

export function colorRect(topLeftX,topLeftY,boxWidth,boxHeight,fillColor){
  canvasContext.fillStyle = fillColor;
  canvasContext.fillRect(topLeftX,topLeftY, boxWidth,boxHeight);
}

export function colorCircle(centerX,centerY, radius, fillColor){
  canvasContext.fillStyle = fillColor;
  canvasContext.beginPath();
  canvasContext.arc(centerX,centerY, radius, 0,Math.PI*2,true);
  canvasContext.fill();
}

export function colorText(showWords, textX,textY, fillColor){
  canvasContext.fillStyle = fillColor;
  canvasContext.fillText(showWords, textX, textY);
}