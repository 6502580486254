import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';

import * as tf from '@tensorflow/tfjs';
import * as tfd from '@tensorflow/tfjs-data';
import * as faceapi from '@vladmandic/face-api';
import * as tmImage from '@teachablemachine/image';

import './App.css';
import './Mask.scss';

import MaskContextProvider, { MaskContext } from './contexts/MaskContext';

import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon, RedditIcon } from "react-share";
import { extractFaces } from '@vladmandic/face-api';

import img1 from './assets/mask_detection/img1.png';
import img2 from './assets/mask_detection/img2.png';


let modelPath = `/weights`;
const shareUrl = `robolink.com/mask-detection`;
const maskModelPath = '/mask-model';

let video;
let canvas;
let ctx;
let displaySize = { width: 640, height: 480 };
let demoInterval;

let collectInterval;
let collecting = false;
let _countMask = 0;
let _countNoMask = 0;

let customModel;
let metaData = {"tfjsVersion":"1.3.1","tmVersion":"2.3.1","packageVersion":"0.8.4","packageName":"@teachablemachine/image","timeStamp":"2021-02-11T09:02:26.705Z","userMetadata":{},"modelName":"tm-my-image-model","labels":["mask","no_mask"]};

let isPretrained = false;
let pretrainedModel;

const Mask = () => {
  let history = useHistory();

  const { setUserMaskModel } = useContext(MaskContext);

  const [ page, setPage ] = useState(0);
  const [ submittedEmail, setSubmittedEmail ] = useState(false);
  const [ loadFormTimes, setLoadFormTimes ] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ cantLoadWebcam, setCantLoadWebcam ] = useState(false);
  const [ running, setRunning ] = useState(false);
  const [ currentDataLabel, setCurrentDataLabel ] = useState('mask');
  const [ countMask, setCountMask ] = useState(0);
  const [ countNomask, setCountNomask ] = useState(0);

  const [predicting, setPredicting] = useState(false);
  const [ label, setLabel ] = useState('');
  const [ maskProb, setMaskProb ] = useState(0);
  const [ nomaskProb, setNomaskProb ] = useState(0);
  const [ runningDemo, setRunningDemo ] = useState(false);
 
  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    let checkSubmittedEmailInStorage = localStorage.getItem("submittedEmail");
    if (checkSubmittedEmailInStorage) {
      setSubmittedEmail(true);
    }
  }, []);

  const handleSubmitEmail = () => {
    setLoadFormTimes(loadFormTimes + 1);
    if (loadFormTimes > 0) {
      setTimeout( () => {
        setSubmittedEmail(true);
        localStorage.setItem('submittedEmail', true);
      }, 2000);
    }
  }

  const goToDemoPage = () => {
    setPage(2);
    loadWebcam();
  }

  const goToTestPage = () => {
    setPage(3);
    isPretrained = true;
    loadWebcam();
  }

  const loadWebcam = async() => {
    try {
      let constraints = { audio: false, video: {
                          width: { min: 320, ideal: 640, max: 640 },
                          height: { min: 240, ideal: 480, max: 480 }
                        }
      };
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(constraints, handleVideo, videoError);
      }
    } catch (e) {
      console.log(e);
      setCantLoadWebcam(true);
    }
  }

  const handleVideo = async (stream) => {
    video = document.getElementById('faceVideo');
    video.srcObject = stream;
    canvas = document.getElementById('faceCanvas');
    ctx = canvas.getContext('2d');
    faceapi.matchDimensions(canvas, displaySize);

    await loadFaceModel();
    if (isPretrained) await loadPretrainedModel();
    else await loadMobileNet();
  }

  const videoError = () => {
    setLoading(false);
    setCantLoadWebcam(true);
  }

  const loadFaceModel = async() => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(modelPath);
    await faceapi.nets.faceLandmark68Net.loadFromUri(modelPath);
    await faceapi.detectSingleFace(video);
    setLoading(false);
  }

  const loadMobileNet = async() => {
    // controller = new ControllerDataset(NUM_CLASSES);
    customModel = await tmImage.createTeachable(metaData);
    customModel.prepareDataset();
    // console.log(customModel);
  }

  const loadPretrainedModel = async() => {
    pretrainedModel = await tmImage.load(maskModelPath + '/mymodel.json', metaData);
    console.log(pretrainedModel);
  }

  const startDemo = () => {
    demoInterval = setInterval(() => {
      predictPretrained();
    }, 500);
    setRunningDemo(true);
  }

  const stopDemo = () => {
    clearInterval(demoInterval);
    setTimeout(() => {
      ctx.clearRect(0, 0, 640, 480);
    }, 1000);
    setRunningDemo(false);
  }

  const detectFace = async() => {
    const detection = await faceapi.detectSingleFace(video).withFaceLandmarks();
    if(detection === undefined) {
      // console.log('no face');
      return;
    }
    const rect = detection.alignedRect.box;
    const regionsToExtract = [
      new faceapi.Rect(rect._x, rect._y, rect._width, rect._height)
    ];
    const extractedFace = await faceapi.extractFaces(video, regionsToExtract);
    // // let testDrawingDiv = document.getElementById('testdrawing');
    // // testDrawingDiv.appendChild(extracedFace[0]);
    ctx.clearRect(0, 0, 640, 480);
    ctx.beginPath();
    ctx.lineWidth = "3";
    ctx.strokeStyle = "blue";
    // ctx.rect(jawline[2].x, nose[2].y, jawline[14].x - jawline[2].x, jawline[8].y - nose[2].y);
    ctx.rect(rect._x, rect._y, rect._width, rect._height);
    ctx.stroke();
    return extractedFace[0];
  }

  const detectNoseToJaw = async() => {
    const detection = await faceapi.detectSingleFace(video).withFaceLandmarks();
    if(detection === undefined) {
      // console.log('no face');
      return;
    }
    const landmark = detection.landmarks;
    const nose = landmark.getNose();
    const jawline = landmark.getJawOutline();
    const regionsToExtract = [
      new faceapi.Rect(jawline[2].x, nose[2].y, jawline[14].x - jawline[2].x, jawline[8].y - nose[2].y)
    ];
    const extractedFace = await faceapi.extractFaces(video, regionsToExtract);
    // let testDrawingDiv = document.getElementById('testdrawing');
    // testDrawingDiv.appendChild(extracedFace[0]);
    ctx.clearRect(0, 0, 640, 480);
    ctx.beginPath();
    ctx.rect(jawline[2].x, nose[2].y, jawline[14].x - jawline[2].x, jawline[8].y - nose[2].y);
    ctx.stroke();
    return extractedFace[0];
  }

  const resizeImage = async(beforeC) => {
    let beforeCtx = beforeC.getContext('2d');    
    let resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 224;
    resizedCanvas.height= 224;
    let resizedCtx = resizedCanvas.getContext('2d');
    resizedCtx.drawImage(beforeCtx.canvas, 0, 0, 224, 224);
    return resizedCanvas;
  }

  const collect = async() => {
    if(!collecting) return;
    let i = currentDataLabel === 'mask' ? 0 : 1;

    // let croppedFace = await detectNoseToJaw();
    let croppedFace = await detectFace();
    if(croppedFace === undefined) return;
    let resizedFace = await resizeImage(croppedFace);
    await customModel.addExample(i, resizedFace);

    if(i === 0) {
      setCountMask(_countMask+1);
      _countMask += 1;
    }
    else {
      setCountNomask(_countNoMask+1);
      _countNoMask += 1;
    }
  }

  const onClickCollect = () => {
    collecting = true;
    collectInterval = setInterval(() => {
      collect();
    }, 1000);
  }

  const stopCollect = () => {
    collecting = false;
    clearInterval(collectInterval);
  }

  const train = async() => {
    const params = {
      denseUnits: 100,
      learningRate: 0.001,
      batchSize: 16,
      epochs: 50,
    }
    await customModel.train(params);
    // console.log(customModel);
    setPredicting(true);
    setUserMaskModel(customModel);
  }

  const predict = async() => {
    // let croppedFace = await detectNoseToJaw();
    let croppedFace = await detectFace();
    if(croppedFace === undefined) return;
    let resizedFace = await resizeImage(croppedFace);
    let prediction = await customModel.predict(resizedFace, false);
    // console.log(prediction);

    // console.log(prediction[0]);
    let mask = prediction[0].probability;
    let nomask = prediction[1].probability;
    // console.log(mask);
    // console.log(nomask);

    let label = mask > nomask ? "mask" : "no mask";
    setLabel(label);
    setMaskProb(mask);
    setNomaskProb(nomask);
  }

  const predictPretrained = async() => {
    let croppedFace = await detectFace();
    if(croppedFace === undefined) return;
    let resizedFace = await resizeImage(croppedFace);
    let prediction = await pretrainedModel.predict(resizedFace, false);
    // console.log(prediction);

    // console.log(prediction[0]);
    let mask = prediction[0].probability;
    let nomask = prediction[1].probability;
    // console.log(mask);
    // console.log(nomask);

    let label = mask > nomask ? "mask" : "no mask";
    setLabel(label);
    setMaskProb(mask);
    setNomaskProb(nomask);
  }

  const save = async() => {
    const saveResult = await customModel.save('downloads://mymodel');
    // console.log(saveResult);
  }

  const goToBlocklyTest = () => {
    history.push('/face-with-blockly');
  }

  const shareDiv = (
    <div className="share-icons">
      <FacebookShareButton url={shareUrl} className="icon">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="icon">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} className="icon">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={shareUrl} className="icon">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <RedditShareButton url={shareUrl} className="icon">
        <RedditIcon size={32} round />
      </RedditShareButton>
      <EmailShareButton url={shareUrl} className="icon">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );

  return (
    <div className="Mask">
      { page === 0 && (
        <div className={submittedEmail ? "content page0": "content page0 small"}>
          <header><h1>Robolink AI web app free trial</h1></header>
          <div>
            { submittedEmail ? (
              <div>
                <p>Are you ready to try out our first AI demo?</p>
                <button onClick={() => setPage(1)}>Yes!</button>
              </div>
            ) : (
              <div>
                <p>Hello! Welcome to the Robolink AI web app free trial.</p>
                <p>We are at the very first step, but we are going to show you more AI demo later!</p>
                <p>Before we start, please submit your email address to access free trial AI demo</p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdmhBK856H4tkSW-z-3ILxcBHbf47ck96ZyifK4e6X61JfUww/viewform?embedded=true" onLoad={handleSubmitEmail} width="640" height="423" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              </div>
            )}
          </div>
        </div>
      )}
      { page === 1 && (
        <div className='page1 content'>
          <h2>Mask detection</h2>
          <div className='oval'>
            <button onClick={goToTestPage}>Test</button>
            <button onClick={() => setPage(4)}>Train</button>
            {/* <button onClick={goToBlocklyTest}>Blockly</button> */}
          </div>
        </div>
      )}

      {page === 4 && (
        <div className='content page4'>
          <h2>Mask detection</h2>
          <div className='description'>
            We already learned about face detection.&nbsp;
            <a href='https://ai.robolink.com/facial-expression-recognition'>(Go to Facial expression recognition demo to learn about face detection)</a>&nbsp;
            We can extract face area with <b>face detection</b>.
            And we are going to use <b>image classification</b> to detect if people wear face mask or not.<br/>
            <div className='row'>
              <div className='col-6'>
                <img src={img1} alt="mask"/>
              </div>
              <div className='col-6'>First we are going to find face area in the picture using face detection.</div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <img src={img2} alt="mask" className='width-50'/>
              </div>
              <div className='col-6'>And we are going to add this extracted face data into each of label either mask or no mask.</div>
            </div>
          </div>
          <button onClick={goToDemoPage}>Next</button>
        </div>
      )}

      {page === 2 && ( /* train */
        <div className='content page2'>
          <h2>Mask detection</h2>
          <div className='row'>
            <div className='col-6'>
              <div className={currentDataLabel === 'mask' ? 'label current': 'label'} onClick={() => setCurrentDataLabel('mask')}>Mask</div>
              <div className='count'>{countMask}</div>
            </div>
            <div className='col-6'>
              <div className={currentDataLabel === 'no_mask' ? 'label current': 'label'} onClick={() => setCurrentDataLabel('no_mask')}>No mask</div>
              <div className='count'>{countNomask}</div>
            </div>
          </div>
          <div className='faceDiv'>
            <video id='faceVideo' autoPlay={true}></video>
            <canvas id='faceCanvas'></canvas>
            <div id='testdrawing'></div>
          </div>
          {predicting && (
            <div style={{color: 'white'}}>
              <div>label : {label}</div>
              <div>mask : {(maskProb*100).toFixed(0)}%</div>
              <div>no mask : {(nomaskProb*100).toFixed(0)}%</div>
            </div>
          )}
          {!predicting && (
            <div>
              <button onClick={onClickCollect} className='collect'>collect</button>
              <button onClick={stopCollect} className='stop'>Stop</button>
              <button onClick={train} className='train'>Train</button>
            </div>
          )}
          {predicting && (
            <div>
              <button onClick={predict}>Test</button>
              <button onClick={goToBlocklyTest}>Test with Blockly</button>
            </div>
            
          )}
          {/* {predicting && (<button onClick={save}>Save</button>)} */}
        </div>
      )}

      {page === 3 && ( /* test */
        <div className='content page3'>
          <h2>Mask detection</h2>
          <div className='faceDiv'>
            <video id='faceVideo' autoPlay={true}></video>
            <canvas id='faceCanvas'></canvas>
            <div id='testdrawing'></div>
          </div>
          
          { loading ? (
            <div className='loading'>loading...</div>
          ) : (
            <div>
              { runningDemo && (
                <div className='result'>
                  <div>label : {label}</div>
                  <div>mask : {(maskProb*100).toFixed(0)}%</div>
                  <div>no mask : {(nomaskProb*100).toFixed(0)}%</div>
                </div>
              )}
              
              { runningDemo ? (
                <button onClick={stopDemo}>Stop</button>
              ) : (
                <button onClick={startDemo}>Start</button>
              )}
              
            </div>
            
          )}
          
        </div>
      )}
    </div>
  );
}

export default Mask;