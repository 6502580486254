import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';

import './MainPage.scss';

const cameraList = ['Object Detection', 'Skeletal Tracking', 'Facial Expression Recognition', 'Mask Detection'];

const MainPage = (props) => {
  let history = useHistory();

  useEffect(() => {
    ReactGA.initialize('UA-81850614-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className='MainPage container'>
      <h1>AI app</h1>
      <div className='row'>
        <div className='type' onClick={() => history.push('/camera')}>
          <div className='title'>Camera(Image)</div>
          <div className='description'>
            {cameraList.map((c, i) => {
              return <p key={i}>{c}</p>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPage;
